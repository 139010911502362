import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {IsInRoleFn, IWorkContext, IWorkContextHolder, PortalRole} from "./models";
import {useGetWorkContext} from "./hooks";
import {Spinner} from '@fluentui/react';
import {useTenantInfo} from "../TenantProvider";

const _isInRole: IsInRoleFn = (context: IWorkContext, ...roles: PortalRole[]): boolean => (roles ?? []).some(r => (context.user?.identityUser?.roles ?? []).includes(r));

const WorkContext = createContext<IWorkContextHolder>({
    isWorkContextSet: false,
    workContext: undefined,

    isContraventionReportSectionEnabled: true,
    setIsContraventionReportSectionEnabled: (value: boolean) => {
    },
    isJobPortalEnabled: true,
    setIsJobPortalEnabled: (value: boolean) => {
    },

    isCurrentUserJobAuditor: false,
    isCurrentUserJobManager: false,
    isCurrentUserJobPartner: false,

    isCurrentUserAdministrator: false,
    isInRole: () => false,
    isInRoleOrSuperAdministrator: () => false,

    isJobDispatched: false,
    setIsJobDispatched: (value: boolean) => {
    }
});

export const useWorkContext = () => useContext(WorkContext);

export const WorkContextProvider = ({...props}: PropsWithChildren<{}>) => {
    const {isBlackLabel} = useTenantInfo();
    const {workContext, isWorkContextLoading} = useGetWorkContext();

    const [currentContext, setCurrentContext] = useState<IWorkContext | undefined>(workContext);
    const [isCurrentContextSet, setIsCurrentContextSet] = useState<boolean>(!!currentContext);

    const [isContraventionReportSectionEnabled, setIsContraventionReportSectionEnabled] = useState<boolean>(true);
    const [isJobPortalEnabled, setIsJobPortalEnabled] = useState<boolean>(true);

    const [isCurrentUserJobAuditor, setIsCurrentUserJobAuditor] = useState<boolean>(false);
    const [isCurrentUserJobManager, setIsCurrentUserJobManager] = useState<boolean>(false);
    const [isCurrentUserJobPartner, setIsCurrentUserJobPartner] = useState<boolean>(false);

    const isInRole = useCallback((...roles: PortalRole[]): boolean => !!currentContext && _isInRole(currentContext, ...roles), [currentContext]);
    const isInRoleOrSuperAdministrator = useCallback((...roles: PortalRole[]): boolean => isInRole(...[...roles, PortalRole.SuperAdministrator, PortalRole.SuperUser]), [isInRole]);

    const isAdministrator = useMemo(() => isInRole(PortalRole.CPAdministrator) && isBlackLabel || isInRole(...[PortalRole.Administrator, PortalRole.SuperAdministrator]), [isInRole, isBlackLabel]);

    const setIsJobDispatched = (value: boolean) => {
        const job = ({...currentContext?.job, isDispatched: value});
        setCurrentContext(prev => ({...prev, job: job}) as IWorkContext);
    }

    useEffect(() => {
        // Current Context value is set here
        console.debug("[CONTEXT::SET]", workContext);
        setCurrentContext(workContext)
    }, [workContext]);

    useEffect(() => {
        // Access to context values is set here
        setIsCurrentContextSet(!!currentContext);

        setIsContraventionReportSectionEnabled(currentContext?.isContraventionTabEnabled ?? true);
        setIsJobPortalEnabled(currentContext?.isAllTabsEnabled ?? false);

        setIsCurrentUserJobAuditor(!!currentContext?.isCurrentUserJobAuditor);
        setIsCurrentUserJobManager(!!currentContext?.isCurrentUserJobManager);
        setIsCurrentUserJobPartner(!!currentContext?.isCurrentUserJobPartner);

    }, [currentContext]);

    if (isWorkContextLoading) {
        return (
            <Spinner>Context Loading ... </Spinner>
        )
    }

    return (
        <WorkContext.Provider
            value={{
                isWorkContextSet: isCurrentContextSet,
                workContext: currentContext,

                isContraventionReportSectionEnabled: isContraventionReportSectionEnabled,
                setIsContraventionReportSectionEnabled: setIsContraventionReportSectionEnabled,
                isJobPortalEnabled: isJobPortalEnabled,
                setIsJobPortalEnabled: setIsJobPortalEnabled,

                isCurrentUserJobAuditor: isCurrentUserJobAuditor,
                isCurrentUserJobManager: isCurrentUserJobManager,
                isCurrentUserJobPartner: isCurrentUserJobPartner,

                isInRole: isInRole,
                isInRoleOrSuperAdministrator: isInRoleOrSuperAdministrator,

                isCurrentUserAdministrator: isAdministrator,

                isJobDispatched: !!currentContext?.job?.isDispatched,
                setIsJobDispatched: setIsJobDispatched
            }}>
            {props.children}
        </WorkContext.Provider>
    )
};