import React, {FunctionComponent, ReactElement, useEffect, useMemo} from 'react';
import {Spinner, SpinnerSize, Stack} from '@fluentui/react';
import {useIntl} from 'react-intl';
import {useGetFundJobs} from "../hooks/useGetFundJobs";
import {useParams, useSearchParams} from "react-router-dom";
import {AdministrationList} from "./AdministrationList";
import {useAdministrationContext} from "../JobsLayoutPage";
import {IAdministrationListItem, IButtonConfig} from "../../../../../interfaces";
import {useBoolean} from '@fluentui/react-hooks';
import {DocumentsModal} from './documents/DocumentsModal';
import {PortalRole, useWorkContext} from "../../../../../providers";


interface IJobsListProps {
    toggleShowAuditQueriesPage: () => void;
    toggleShowInitialInformationChecklistPage: () => void;
    setCurrentJobGuid: (guid: string) => void;
    currentJobGuid: string;
}

export const JobsList: FunctionComponent<IJobsListProps> = ({
                                                                toggleShowAuditQueriesPage,
                                                                toggleShowInitialInformationChecklistPage,
                                                                setCurrentJobGuid,
                                                                currentJobGuid
                                                            }) => {
    const {filters} = useAdministrationContext();
    const {isInRole} = useWorkContext();
    const {fundId} = useParams();
    const {formatMessage} = useIntl();
    const searchParams = useSearchParams();
    const {jobsData, isJobsLoading: isLoading, isJobsFetching} = useGetFundJobs({fundId: fundId as string});


    const hasAccess = useMemo(() => {
        return !isInRole(...[PortalRole.CPUser]);
    }, [isInRole]);

    useEffect(() => {
        if (searchParams[0].get("showQueries") === "true" && !!searchParams[0].get("jobGuid")) {
            setCurrentJobGuid(searchParams[0].get("jobGuid")!);
            toggleShowAuditQueriesPage();
        } else if (searchParams[0].get("showIic") === "true" && !!searchParams[0].get("jobGuid")) {
            setCurrentJobGuid(searchParams[0].get("jobGuid")!);
            toggleShowInitialInformationChecklistPage();
        }
    }, []);

    const [openDocumentsModal, {toggle: toggleOpenDocumentsModal}] = useBoolean(false);

    const onItemClick = (event: any, guid: string, item: IAdministrationListItem) => {
        if (!hasAccess) {
            return;
        }
        console.log(item);
        window.open(`/job-portal/${item.guid}`, '_blank',)
    }
    const buttonsConfig: IButtonConfig[] = [
        {
            text: formatMessage({id: 'initialInformationChecklist'}),
            type: 'primary',
            onClick: (data: any) => {
                setCurrentJobGuid(data.guid);
                toggleShowInitialInformationChecklistPage();
            },
        },
        {
            text: formatMessage({id: 'auditQueries'}),
            type: 'primary',
            onClick: (data: any) => {
                setCurrentJobGuid(data.guid);
                toggleShowAuditQueriesPage();
            },
        },
        {
            text: formatMessage({id: 'documents'}),
            type: 'primary',
            onClick: (data: any) => {
                setCurrentJobGuid(data.guid);
                toggleOpenDocumentsModal();
            },
        },
    ];

    const items = useMemo(() => {
        const jobs = jobsData?.data?.sort((x, y) => x.year < y.year ? -1 : 1).map(({id, year, guid, dateARSent}) => ({
            id,
            name: year + ' Audit',
            guid: guid,
            showAcceptIcon: !!dateARSent,
            disabled: !hasAccess,
        })) as IAdministrationListItem[];

        const searchItems = filters?.search
            ? jobs?.filter(x => x.name.toString().includes(filters.search?.toLowerCase() as string))
            : jobs;

        return searchItems || [];
    }, [filters, jobsData?.data]);


    if (isLoading || isJobsFetching) return (<Stack styles={{root: {height: '100%'}}} verticalAlign={"center"}>
        <Spinner size={SpinnerSize.large} label={"Loading"}/>
    </Stack>);

    const onRenderItem = (item: IAdministrationListItem): ReactElement => {
        return <Stack.Item>{item?.name}</Stack.Item>
    }

    return (
        <>
            <AdministrationList
                onCardClick={onItemClick}
                isLoading={isLoading}
                items={items}
                itemIcon={{iconName: 'OpenInNewTab'}}
                buttonsConfig={buttonsConfig}/>
            <DocumentsModal open={openDocumentsModal} toggle={toggleOpenDocumentsModal} jobGuid={currentJobGuid}/>
        </>
    );
};