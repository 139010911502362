import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {getFlatProcedures, getOpenOnStartupIds} from 'utils';
import {
    Attachment,
    GeneratedAnswer,
    JobComment,
    ProceduresDataTable,
    useProcedureContext, useSectionContext
} from 'pages/JobPortal/components';
import {SanitizedText} from 'components';
import {useIntl} from 'react-intl';
import {IconButton, Stack, Text} from '@fluentui/react';
import {CalculatorTemplate, getTypeByLink} from "../../Calculators";
import {ColumnInfo} from "../../../enums";
import {WorkDoneSwitchAnswer} from "../answers";

export const ComparativeInformationProcedureTemplate: FunctionComponent = () => {
    const {items, isLoading} = useProcedureContext();
    const { sectionAnswersRefreshedTick, section, enableWarningMessage, setApprovingDisable } = useSectionContext();
    const {formatMessage} = useIntl();
    const [expandedItemIds, setExpandedItemIds] = useState<number[]>(getOpenOnStartupIds(items));
    const [isSectionPartnerAnswerSet, setIsSectionPartnerAnswerSet] = useState<boolean>(false);
    const rows = useMemo<any[]>(() => getFlatProcedures(items, expandedItemIds), [items, expandedItemIds]);

    useEffect(() => {
        setIsSectionPartnerAnswerSet(!!section.sectionAnswer?.partnerApproval)
    }, [sectionAnswersRefreshedTick])

    useEffect(() => {
        const some = rows.some(x => !!x.answerText)

        const timeout = setTimeout(() => {
            console.debug("[COMPERATIVE_INFO::EFFECT:TIMEOUT]");

            enableWarningMessage(!some, true);
            setApprovingDisable(!some);
        }, 300)
        
        return () => {
            clearTimeout(timeout);
        }
    }, [items])
    
    const onAnswerUpdate = useCallback((newText: string | null) => {
        enableWarningMessage(!newText, true);
        setApprovingDisable(!newText)
    }, [])
    
    return (
        <ProceduresDataTable
            items={rows}
            isLoading={isLoading}
            columns={[
                {
                    key: 'actions',
                    name: formatMessage({id: 'actions'}),
                    fieldName: 'actions',
                    onRender: (items) => (
                        <Stack horizontal>
                            {items?.showAttachment &&
                                <Attachment itemId={items?.id} hasAttachments={items?.hasAttachments}/>}
                            {items?.showComment && <JobComment itemId={items?.id} hasComments={items?.hasComments}/>}
                        </Stack>
                    ),
                    minWidth: 100,
                    maxWidth: 128,
                },
                {
                    key: 'auditProcedure',
                    name: formatMessage({id: 'auditProcedure'}),
                    fieldName: 'auditProcedure',
                    onRender: (items) => (
                        <Stack horizontal tokens={{childrenGap: 16}} styles={{root: {maxWidth: '100%'}}}>
                            <Text>{items.number}</Text>
                            <SanitizedText data={items?.title} styles={{
                                root: {
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellypsis'
                                }
                            }}/>
                        </Stack>
                    ),
                    minWidth: 200,
                },
                {
                    key: 'result',
                    name: formatMessage({id: 'result'}),
                    fieldName: 'result',
                    onRender: (items) => (
                        <Stack verticalAlign='center' grow>
                            <WorkDoneSwitchAnswer 
                                reverse 
                                value={items.answerText} 
                                iconTypes={'character'}
                                disabled={items?.answerText && isSectionPartnerAnswerSet}
                                itemId={items?.id} 
                                onUpdate={onAnswerUpdate}
                                tableType={items.tableType} 
                                columnInfo={ColumnInfo.Text}/>
                        </Stack>
                    ),
                    minWidth: 220,
                    maxWidth: 220,
                },
            ]}
        />
    );
};