import {useContext} from "react";
import {commonHeaders, Context, queryClient} from "../../../../../utils";
import {useMutation} from "react-query";
import axios, { AxiosError } from "axios";
import {_InvoiceControllerName} from "./index";
import {InvoiceParagraphType} from "../enums";
import {NewInvoice} from "../interfaces";
import { useInvoicePortalNotifications } from "../../../hooks";

interface ICreateInvoiceRequest {
    requests: NewInvoice[],
    applyCreditNotes: boolean,
    creationDate?: Date,
    paragraph?: InvoiceParagraphType
}

function useCreateInvoice(clientIdentifier: string) {
    const context = useContext(Context);
    const {showOnCreateNotification} = useInvoicePortalNotifications()
    
    const {
        mutate: create,
        mutateAsync: createAsync,
        isLoading: isCreating
    } = useMutation((data: ICreateInvoiceRequest) => axios.post(`${context?.config?.billingUrl}/api/1.0/${_InvoiceControllerName}/Create/${clientIdentifier}`, data.requests ?? [], {
        ...commonHeaders(),
        params: {
            applyCreditNotes: data.applyCreditNotes,
            creationDate: data.creationDate,
            paragraph: data.paragraph
        }
    }), {
        onSuccess: () => { 
            queryClient.invalidateQueries('getInvoices');
            showOnCreateNotification.success({ values: { name: 'Invoice' }})
        },
        onError: (error: AxiosError) => {
            showOnCreateNotification.error({ values: { ...error?.response?.data ?? {} }})
        }
    });

    return {create, createAsync, isCreating};
}

export {useCreateInvoice};